let $ = require('jquery');
window.$ = window.jQuery = $;

require('hammerjs');
require('popper.js');
require('bootstrap');
require('waypoints/lib/jquery.waypoints');

$ = window.$;

$(document).ready(function() {
  toggle_nav_container();
});

var toggle_nav_container = function() {
  var $toggleButton = $('#toggle_m_nav');
  $navContainer = $('#m_nav_container');
  $menuButton = $('#m_nav_menu');
  $menuButtonBars = $('.m_nav_ham');

  // toggle the container on click of button (can be remapped to $menuButton)

  $toggleButton.on('click', function() {
    // declare a local variable for the window width
    var $viewportWidth = $(window).width();

    // if statement to determine whether the nav container is already toggled or not

    if ($navContainer.is(':hidden')) {
      $navContainer
        .fadeIn(300)
        .addClass('container_open')
        .css('z-index', '999');
      // $(window).scrollTop(0);
      $menuButtonBars.removeClass('button_closed');
      $menuButtonBars.addClass('button_open');
      $('#m_ham_1').addClass('m_nav_ham_1_open');
      $('#m_ham_2').addClass('m_nav_ham_2_open');
      $('#m_ham_3').addClass('m_nav_ham_3_open');
      $('body').css('overflow', 'hidden');
    } else {
      $navContainer
        .css('z-index', '1')
        .removeClass('container_open')
        .fadeOut(300);
      $menuButtonBars.removeClass('button_open');
      $menuButtonBars.addClass('button_closed');
      $('#m_ham_1').removeClass('m_nav_ham_1_open');
      $('#m_ham_2').removeClass('m_nav_ham_2_open');
      $('#m_ham_3').removeClass('m_nav_ham_3_open');
      $('body').css('overflow', 'initial');
    }
  });
};

// link nav add class active
$(function() {
  $('.header nav a[href^="/' + location.pathname.split('/')[1] + '"]').addClass('active');
});

// scroll to
function getRelatedContent(el) {
  return $($(el).attr('href'));
}
function getRelatedNavigation(el) {
  return $('.btn-scroll[href=#' + $(el).attr('id') + ']');
}

$('.btn-scroll').on('click touch', function(e) {
  e.preventDefault();
  $('html,body').animate({ scrollTop: getRelatedContent(this).offset().top }, 1000);
  $('#m_nav_container')
    .css('z-index', '1')
    .removeClass('container_open')
    .fadeOut(200);
  $('.m_nav_ham').removeClass('button_open');
  $('.m_nav_ham').addClass('button_closed');
  $('#m_ham_1').removeClass('m_nav_ham_1_open');
  $('#m_ham_2').removeClass('m_nav_ham_2_open');
  $('#m_ham_3').removeClass('m_nav_ham_3_open');
  $('body').css('overflow-y', 'initial');
});

// header animation scroll to top
$(document).ready(function($) {
  var mainHeader = $('.cd-auto-hide-header'),
    secondaryNavigation = $('.cd-secondary-nav'),
    //this applies only if secondary nav is below intro section
    belowNavHeroContent = $('.sub-nav-hero'),
    headerHeight = mainHeader.height();

  //set scrolling variables
  var scrolling = false,
    previousTop = 0,
    currentTop = 0,
    scrollDelta = 0,
    scrollOffset = 0;

  $(window).on('scroll', function() {
    if (!scrolling) {
      scrolling = true;
      !window.requestAnimationFrame
        ? setTimeout(autoHideHeader, 250)
        : requestAnimationFrame(autoHideHeader);
    }
  });

  $(window).on('resize', function() {
    headerHeight = mainHeader.height();
  });

  function autoHideHeader() {
    var currentTop = $(window).scrollTop();

    belowNavHeroContent.length > 0
      ? checkStickyNavigation(currentTop) // secondary navigation below intro
      : checkSimpleNavigation(currentTop);

    previousTop = currentTop;
    scrolling = false;

    var documentTop = $(document).scrollTop();
    if (documentTop >= 70) {
      $('.cd-auto-hide-header').addClass('shadow-header');
    } else {
      if (documentTop <= 70) {
        $('.cd-auto-hide-header').removeClass('shadow-header');
      }
    }
  }

  function checkSimpleNavigation(currentTop) {
    //there's no secondary nav or secondary nav is below primary nav
    if (previousTop - currentTop > scrollDelta) {
      //if scrolling up...
      mainHeader.removeClass('is-hidden');
    } else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
      //if scrolling down...
      mainHeader.addClass('is-hidden');
    }
  }
});
