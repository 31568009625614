let $ = require('jquery');
const randomFromCollection = require('random-from-collection');

window.$ = window.jQuery = $;
require('hammerjs');
require('popper.js');
require('bootstrap');
require('./header.js');
require('owl.carousel');

$ = window.$;

const HERO_CARDS_SET = new Set([
  {
    imgSrc: require('../images/home/card-diabetes-protection.png'),
    href: 'https://www.nature.com/articles/ng.3620',
  },
  {
    imgSrc: require('../images/home/card-low-cholesterol.png'),
    href: 'https://www.nature.com/articles/ng1509',
  },
  {
    imgSrc: require('../images/home/card-strong-bones.png'),
    href: 'https://www.nejm.org/doi/10.1056/NEJMoa013444',
  },
  {
    imgSrc: require('../images/home/card-cholera-resistance.png'),
    href: 'https://stm.sciencemag.org/content/5/192/192ra86.abstract',
  },
  {
    imgSrc: require('../images/home/card-unique-metabolism.png'),
    href: 'https://science.sciencemag.org/content/349/6254/1343.long',
  },
  {
    imgSrc: require('../images/home/card-hiv-resistance.png'),
    href: 'https://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.0030339',
  },
  {
    imgSrc: require('../images/home/card-arsenic-resistance.png'),
    href: 'https://academic.oup.com/mbe/article/32/6/1544/1074042',
  },
  {
    imgSrc: require('../images/home/card-natural-divers.png'),
    href: 'https://www.cell.com/cell/fulltext/S0092-8674(18)30386-6',
  },
  {
    imgSrc: require('../images/home/card-alzheimer-protection.png'),
    href: 'https://www.nature.com/articles/s41591-019-0611-3',
  },
  {
    imgSrc: require('../images/home/card-altitude-adaptation.png'),
    href: 'http://genesdev.cshlp.org/content/28/20/2189.long',
  },
]);

const randomCards = randomFromCollection(HERO_CARDS_SET, 6);

const populateCard = function(index) {
  $(this).attr('href', randomCards[index].href);
  $(this)
    .find('img')
    .attr('src', randomCards[index].imgSrc);
  setTimeout(() => $(this).fadeIn(), 1000);
};

$('.desktop-cards .card').each(populateCard);
$('.mobile-cards .card').each(populateCard);

$(document).ready(function() {
  SequenceAnimation();
  setInterval(SequenceAnimation, 23500);

  $('.owl-carousel').owlCarousel({
    autoWidth: true,
    autoplay: true,
    autoplayHoverPause: false,
    autoplaySpeed: 3333,
    autoplayTimeout: 3333,
    loop: true,
    margin: 30,
    nav: false,
    slideTransition: 'linear',
    startPosition: 0,
    touchDrag: true,
  });
});

var SequenceAnimation = function() {
  if (window.innerWidth <= 640) {
    setTimeout(function() {
      $('#search-loop')
        .get(0)
        .play();
      $('#search-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#treat-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
      $('#dot-1')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
      $('#search')
        .addClass('active')
        .siblings('.col-12')
        .removeClass('active');
    }, 100);

    setTimeout(function() {
      $('#sequence-loop')
        .get(0)
        .play();
      $('#sequence-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#search-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
      $('#dot-2')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
      $('#sequence')
        .addClass('active')
        .siblings('.col-12')
        .removeClass('active');
    }, 6000);

    setTimeout(function() {
      $('#discover')
        .addClass('active')
        .siblings('.col-12')
        .removeClass('active');
      $('#discover-loop')
        .get(0)
        .play();
      $('#dot-3')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
      $('#discover-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#sequence-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
    }, 11800);

    setTimeout(function() {
      $('#treat')
        .addClass('active')
        .siblings('.col-12')
        .removeClass('active');
      $('#treat-loop')
        .get(0)
        .play();
      $('#dot-4')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
      $('#treat-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#discover-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
    }, 17500);
  } else {
    setTimeout(function() {
      $('#search-loop')
        .get(0)
        .play();
      $('#search-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#treat-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
      $('#dot-1')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
    }, 100);

    setTimeout(function() {
      $('#sequence-loop')
        .get(0)
        .play();
      $('#sequence-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#search-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
      $('#dot-2')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
    }, 6000);

    setTimeout(function() {
      $('#discover-loop')
        .get(0)
        .play();
      $('#dot-3')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
      $('#discover-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#sequence-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
    }, 11800);

    setTimeout(function() {
      $('#treat-loop')
        .get(0)
        .play();
      $('#dot-4')
        .addClass('active')
        .siblings('.dot')
        .removeClass('active');
      $('#treat-container')
        .closest('.col-12')
        .find('p')
        .addClass('active');
      $('#discover-container')
        .closest('.col-12')
        .find('p')
        .removeClass('active');
    }, 17500);
  }
};
$(function() {
  $('.header nav a').removeClass('active');
});
